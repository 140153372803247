import React from 'react';
import { Container, Typography, Box, List, ListItem, ListItemIcon, ListItemText, Paper, makeStyles, Button } from "@material-ui/core";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#f5f5f5',
    // minHeight: '100vh',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(4),
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  },
  title: {
    marginBottom: theme.spacing(4),
    color: '#6d1f5d', // Updated color
    fontWeight: 'bold',
  },
  subtitle: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    color: '#6d1f5d', // Updated color
  },
  listItem: {
    marginBottom: theme.spacing(2),
  },
  bullet: {
    color: '#6d1f5d', // Updated color
    fontSize: 'small',
  },
  backButton: {
    marginBottom: theme.spacing(2),
  },
}));

const TermsPage = () => {
  const classes = useStyles();
  const history = useHistory();

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <div className={classes.root}>
      <Container maxWidth="md">
        <Button
          className={classes.backButton}
          startIcon={<ArrowBackIcon />}
          onClick={handleGoBack}
        >
          Back
        </Button>
        <Paper className={classes.paper}>
          <Typography variant="h4" component="h1" className={classes.title} gutterBottom>
            BeetPOS Terms & Conditions
          </Typography>
          
          <Typography variant="body1" paragraph>
            BeetPOS grants you the right to access and use the Beetpos Services through 
            the Beetpos Website with a usage role that has been defined for you, 
            according to the type of service you have selected.
          </Typography>
          
          <Typography variant="body1" paragraph>
            This right is non-exclusive, non-transferable and limited by and contingent on
            this agreement. You acknowledge and agree to, and subject to 
            any written agreement in force between Customer and Invited User, or
            other applicable law:
          </Typography>
          
          <Typography variant="h6" className={classes.subtitle}>
            Customer Responsibilities
          </Typography>
          
          <List>
            <ListItem className={classes.listItem}>
              <ListItemIcon>
                <FiberManualRecordIcon className={classes.bullet} />
              </ListItemIcon>
              <ListItemText>
                That it is the responsibility of the Customer to determine who has access
                as an Invited User and the types of roles and rights they have to
                access the types of data you have.
              </ListItemText>
            </ListItem>
            
            <ListItem className={classes.listItem}>
              <ListItemIcon>
                <FiberManualRecordIcon className={classes.bullet} />
              </ListItemIcon>
              <ListItemText>
                That the Customer's responsibility for all use of the Service by the Invited
                User.
              </ListItemText>
            </ListItem>
            
            <ListItem className={classes.listItem}>
              <ListItemIcon>
                <FiberManualRecordIcon className={classes.bullet} />
              </ListItemIcon>
              <ListItemText>
                That it is the Customer's responsibility to control each Invited User's level
                of access to the relevant organization and Services at any time, and
                may withdraw or change the Invited User's access or access level at
                any time, for any reason in any case.
              </ListItemText>
            </ListItem>
            
            <ListItem className={classes.listItem}>
              <ListItemIcon>
                <FiberManualRecordIcon className={classes.bullet} />
              </ListItemIcon>
              <ListItemText>
                That it is the Customer's responsibility to control each Invited User's level
                of access to the relevant organization and Services at any time, and
                may withdraw or change the Invited User's access or access level at
                any time, for any reason in any case.
              </ListItemText>
            </ListItem>
          </List>
        </Paper>
      </Container>
    </div>
  );
}

export default TermsPage;