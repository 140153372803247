import React from 'react';
import { Container, Typography, Grid, Paper, makeStyles, Button } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff1f1',
    minHeight: '100vh',
    padding: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(4),
    backgroundColor: '#fff1f1',
    boxShadow: 'none',
  },
  title: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(4),
  },
  logo: {
    width: '300px',
    height: 'auto',
    marginBottom: theme.spacing(4),
  },
  subtitle: {
    fontWeight: 'bold',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  footer: {
    marginTop: theme.spacing(4),
    fontSize: '0.9rem',
    color: '#666',
  },
  backButton: {
    marginBottom: theme.spacing(2),
  },
}));

const ContactPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const currentYear = new Date().getFullYear();

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <div className={classes.root}>
      <Container maxWidth="md">
        <Button
          className={classes.backButton}
          startIcon={<ArrowBackIcon />}
          onClick={handleGoBack}
        >
          Back
        </Button>
        <Paper className={classes.paper}>
          <Typography variant="h4" className={classes.title}>
            KONTAK KAMI
          </Typography>
          <img 
            src="https://beetpos.com/wp-content/uploads/2022/04/logo-beetpos-update-3-300x112.png" 
            alt="BeetPOS Logo" 
            className={classes.logo}
          />
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" className={classes.subtitle}>
                Head Quarter
              </Typography>
              <Typography>
                JL. Green Lake City ,Cordoba Blok D28 Boulevard, Petir, Cipondoh, Kota Tangerang, Banten
              </Typography>
              <Typography variant="h6" className={classes.subtitle}>
                Marketing Office
              </Typography>
              <Typography>
                Gedung Neo Soho Residence unit 1628 – 1629, Podomoro City, Jl. Letjen S. Parman No.Kav.28, RT.14/RW.1, Tj. Duren Sel., Kec. Grogol petamburan, Kota Jakarta Barat, Daerah Khusus Ibukota Jakarta 11470
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" className={classes.subtitle}>
                Phone
              </Typography>
              <Typography>
                0895 3460 09572
              </Typography>
            </Grid>
          </Grid>
          <Typography className={classes.footer}>
            Copyright © {currentYear} BeetPOS. All rights reserved.<br />
            Powered By PT Lifetech Tanpa Batas.
          </Typography>
        </Paper>
      </Container>
    </div>
  );
};

export default ContactPage;
